/**
 * @module Player
 * @desc Contains player stuff
 */
import { dispatches } from 'lib/event-dispatcher';
import adManager from 'constants/ad-manager-keys';
import adTypes from 'constants/ad-types';
import authTokenProvider from 'lib/auth-token-provider';
import { buildFWVodAds, buildFWTVELinearAds, buildFWT6LinearAds, buildManifestDAI, getFreewheelConfig } from 'lib/ads/freewheel';
import Logger from 'lib/logger';
import castMessenger from 'cast-messenger';
import config from 'config';
import heartbeat from 'heartbeat';
import controllerEvents from 'constants/controller-events';
import heartbeatEvents from 'constants/heartbeat-events';
import messageTypes from 'constants/message-types';
import playerEvents from 'constants/player-events';
import ResumePoints from 'resume-points';
import seekMonitor from 'lib/helpers/player-platform/seek-monitor';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';
import { getVideoAdBreakObject, getMaxBitrateForDevice, throttle } from 'lib/helpers';
import { getHostInfo, resolveAnalyticIds, getPlayerPlatformContentType } from './lib/analytics/viper';
import splunkLogger from 'lib/telemetry/splunk-logger';
import { getProperty } from 'lib/helpers';
import { allDeviceChecks, checkHighBitrateSupport } from './lib/helpers/device-detection';

/* player */
import PlayerAdLogging from 'player/player-ad-logging';
import playerErrorHandler from 'player/player-error-handler';
import splunkTypes from './constants/splunk-types';
import siftTracker from 'sift';
import ppjsLogger from 'lib/debug/ppjs-logger';

const resumePointTimeInterval = 60e3; // 1 minute
let resumePointInterval = null;

const logger = new Logger('PLAYER', { background: 'darkblue', color: 'white' });
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
const assetEngineForWatchable = (watchable) => config.assetEngineMap[watchable.getAssetType()] || 'helio_tawa';

/* eslint-disable valid-jsdoc */
/**
 * Player Component wrapper around Player Platform
 * @hideconstructor
 */
/* eslint-enable valid-jsdoc */
@dispatches('player')
class Player {
  adMode = false;
  adData = null;
  api = {};
  ccEnabled = false;
  channelId = '';
  currentAsset = null;
  currentTokenSummary = {};
  deviceCapabilities = {};
  easActive = false;
  easConfigured = false;
  entity = {};
  error = {
    errorMajor: '',
    errorMinor: ''
  }
  isMidStream = false;
  loggingInfo = {};
  playerPosition = 0;
  playbackMonitor = undefined;
  programId = '';
  restartVideo = false;
  seekMonitorObj = seekMonitor
  senderConnected = false;
  sendVPEventForViewSession = true;
  sendPlayerViewedEvent = true;
  streamUrl = null;
  tveVodCaptionCheckEnabled = false;
  watchable = {};
  viewSessionStart = null;
  errorHandler = playerErrorHandler || {};
  userPrivacyPrefs = {};

  /**
   * addListeners - accepts a target to assign available listeners
   *
   * @param  {type} target   target object to attach to
   * @param  {type} eventMap object list with names and assigned functions
   */
  addListeners(target, eventMap) {
    Object.keys(eventMap).forEach((name) => {
      const listener = eventMap[name];
      if (listener) {
        target.addEventListener(name, listener);
      }
    });
  }

  /**
   * @typedef {Object} PrivacyPref
   * @property {boolean} targetedAdOptIn
   * @property {string} audienceMeasurement
   * @property {string} targetedAdOptIn
   * @property {string} userPrivacyString
   * @property {string} xifa
   */

  /**
   * Attempts to play an asset
   * @function
   * @param  {Object} options
   * @param  {object} options.programId  programId from sender
   * @param  {string} options.channelId  desired channelId to attempt playback with
   * @param  {number} options.resumePoint resume point
   * @param  {object} options.watchable watchable describing the asset
   */
  async playAsset({ programId, channelId, watchable, resumePoint }) {
    const deviceCheck = allDeviceChecks();
    const highSupport = checkHighBitrateSupport();
    this.maximumBitrate = getMaxBitrateForDevice(this.deviceCapabilities, config.maximumBitrate);

    senderDebugger.debugPlayerMessage('[playAsset] Device details with bitrate check:', {
      maxBitrate: `max bitrate: ${this.maximumBitrate}`,
      highBitrateSupport: `high bitrate support: ${highSupport}`,
      deviceCheck: JSON.stringify(deviceCheck, undefined, 4)
    });

    const contentOptions = {
      adConfig: {
        type: adManager.NONE
      },
      authToken: authTokenProvider.token.xsct,
      drmKey: authTokenProvider.token.xsct,
      keySystem: 'com.widevine.alpha',
      audioCapabilitiesRobustness: 'HW_SECURE_CRYPTO',
      videoCapabilitiesRobustness: 'HW_SECURE_ALL',
      preferredStreamingFormat: 'DASH',
      resumePosition: resumePoint < 3000 ? 500 : resumePoint,
      xsct: authTokenProvider.token.xsct,
      adTargetingOptOut: !this.userPrivacyPrefs.targetedAdOptIn,
      userPrivacyString: this.userPrivacyPrefs.userPrivacyString,
      audienceMeasurement: !this.userPrivacyPrefs.audienceMeasurement,
      contentType: getPlayerPlatformContentType(watchable),
      programId: programId
    };

    senderDebugger.debugPlayerMessage('[playAsset] Content Options built:', {
      contentOptions: contentOptions,
      watchableExists: !!watchable
    });
    this.originalResumePoint = Number(resumePoint) ? Number(resumePoint) : 0;

    if (watchable) {
      this.watchable = watchable;
      senderDebugger.debugPlayerMessage(`[playAsset] isLinear? ${this.watchable.isLinear()}`);
      if (this.watchable.isLinear()) {
        this.channelId = channelId;
      } else {
        this.programId = programId;
      }
    }
    senderDebugger.debugPlayerMessage('[playAsset] Load video requested:', {
      watchableAttempt: JSON.stringify(watchable, getCircularReplacer(), 4) });
    this.configureViperAnalytics();
    this.errorHandler.isOfflineErrorActive = false;

    this.currentAsset = null;
    this.streamUrl = null;
    seekMonitor.reset();
    const resolvedAssetEngine = assetEngineForWatchable(this.watchable);
    senderDebugger.debugPlayerMessage(`[playAsset] resolved engine: ${resolvedAssetEngine}`);

    Object.assign(contentOptions, resolveAnalyticIds({ watchable: this.watchable }),
      { assetEngine: resolvedAssetEngine });

    if (watchable.isGeoFenced() || (watchable.channel && watchable.channel.hasGeofencedLocatorType())) {
      Object.assign(contentOptions,
        { xcalStreamType: 'Geofenced' });
    }
    senderDebugger.debugPlayerMessage('[playAsset] Content Options BEFORE StreamContentUrl:', {
      contentOptions: contentOptions,
      isWatchableExternalStream: watchable && watchable.isExternalStream()
    });
    if (watchable && !watchable.isExternalStream()) {
      senderDebugger.debugPlayerMessage('[playAsset] Playing a watchable. Attempting to lookup playable stream');
      const stream = watchable.findPlayableStream();
      const streamContentUrl = stream.contentUrl ? stream.contentUrl : stream.path ? stream.path : null;
      senderDebugger.debugPlayerMessage('[playAsset] Playing a watchable. ', {
        stream: stream,
        streamResource: stream._resource,
        externalStream: stream._resource && stream._resource.isExternal,
        streamContentUrl: streamContentUrl
      });
      if (streamContentUrl !== null) {
        this.currentAsset = new this.playerPlatform.Asset.create(streamContentUrl, contentOptions);
        this.streamUrl = streamContentUrl;
        this.currentAsset.streamContentUrl = streamContentUrl;
        logger.log('SETTING CURRENT ASSET FROM PP: ' + JSON.stringify(this.currentAsset, getCircularReplacer(), 4));
      }
      if (this.currentAsset && contentOptions) {
        this.currentAsset.resumePosition = contentOptions.resumePosition || 0;
      }
    }

    this.videoPlayer.classList.add('starting');
    this.setAdConfig(contentOptions);
    contentOptions.adConfig.xifa = this.userPrivacyPrefs.xifa || '';

    if (watchable.isLinearTve() && watchable.isExternalStream() && !watchable.isXumoStream()) {
      try {
        senderDebugger.debugPlayerMessage('[playAsset] EXTERNAL STREAM CHECK:', {
          currentAsset: this.currentAsset,
          isWatchableExternalStream: watchable && watchable.isExternalStream(),
          contentOptions: contentOptions
        });
        const response = await watchable.getExternalPlayerResource();
        this.handleExternalPlayerResource(response);
        senderDebugger.debugPlayerMessage('[playAsset] EXTERNAL STREAM RESPONSE CHECK:', {
          currentAsset: this.currentAsset,
          isWatchableExternalStream: watchable && watchable.isExternalStream(),
          returnedResponse: response,
          contentOptions: contentOptions
        });
      } catch (e) {
        logger.log(e);
      }
    }

    if (this.watchable.isVirtualStream()) {
      this.setVirtualAsset(contentOptions);
    }

    if (watchable.isOTT() || watchable.isXumoStream()) {
      this.setOTTAsset(contentOptions);
    }

    senderDebugger.debugPlayerMessage('[playAsset] ASSET CHECK:', {
      currentAsset: this.currentAsset,
      isWatchableLinear: watchable && watchable.isLinearTve(),
      isWatchableXumo: watchable && watchable.isXumoStream(),
      isWatchableExternalStream: watchable && watchable.isExternalStream(),
      contentOptions: contentOptions
    });
    this.playCurrentAsset();
    this.startEasAlertByAsset();
    this.isMidStream = false;
  }

  async playCurrentAsset(forcedResumePoint) {
    if (!this.currentAsset) {
      senderDebugger.debugErrorMessage('[PLAYER][playCurrentAsset] No current currentAsset', {
        currentAsset: this.currentAsset
      });
      logger.error('No currently playing asset');
      return;
    }
    if (!this.currentAsset.contentUrl) {
      senderDebugger.debugPlayerMessage('[playCurrentAsset] No currently currentAsset.contentUrl', {
        currentAsset: this.currentAsset,
        contentUrl: this.contentUrl
      });
      this.currentAsset.contentUrl = this.contentUrl ? this.contentUrl : 'not found';
      senderDebugger.debugPlayerMessage('RESET currently this.currentAsset.contentUrl : ' + this.currentAsset.contentUrl);
    }
    senderDebugger.debugPlayerMessage('RESET currently this.currentAsset.contentUrl : ', {
      watchableFuncs: Object.getOwnPropertyNames(this.watchable),
      currentAsset: this.currentAsset
    });
    this.forcedResumePoint = forcedResumePoint ? forcedResumePoint : this.currentAsset.resumePosition;
    splunkLogger.onVideoAttempt(this.watchable, { programId: this.programId });

    this.currentAsset.resumePosition = this.forcedResumePoint < 3000 ? 500 : this.forcedResumePoint;

    heartbeat.start(this.watchable);
    logger.logBlock('playCurrentAsset : ' + JSON.stringify(this.currentAsset.contentOptions, getCircularReplacer(), 4), (logger) => {
      logger.log('attempting to play content options:', JSON.stringify(this.currentAsset.contentOptions, getCircularReplacer(), 4));
      logger.log('attempting to play at position:', JSON.stringify(this.currentAsset.resumePosition, getCircularReplacer(), 4));
    });
    /* Very much uncertain that we still need this. I'd like to keep it commented
     * until John C is able to confirm.
     * */

    if (this.api.getPlayerStatus() !== 'idle') {
      console.warn(`STREAM STOPPING ASSET with STOP call. player status is ${this.api.getPlayerStatus()}`);
      // commented because we might need this :
      // this.api.stop();
      // await new Promise((resolve) => setTimeout(resolve, 5e3));
    }

    if (this.playbackMonitor) {
      this.playbackMonitor.isMediaEnded = false;
    }
    senderDebugger.debugPlayerMessage(`setAsset attempting to play at position: ${ this.currentAsset.resumePosition}`);
    this.api.setAsset(this.currentAsset);
    senderDebugger.debugPlayerMessage('----------------------------------------------PLAY ASSET SENT TO PP -----------------------------');
  }

  restartPlayback() {
    this.stopPlayback();
    this.playAsset({
      programId: this.programId,
      channelId: this.channelId,
      watchable: this.watchable,
      resumePoint: this.watchable.isAnyVod() ? this.playerPosition : undefined
    });
  }

  handleExternalPlayerResource = (externalPlayerResource) => {
    const playableStream = this.watchable.findPlayableStream() || {};
    let streamId = playableStream.externalStreamId || playableStream.streamId;
    const streamProvider = playableStream.provider || playableStream.streamProvider;
    const videoOptions = {
      assetEngine: streamProvider,
      authToken: externalPlayerResource.authToken || '',
      drmKey: externalPlayerResource.authToken || '',
      streamId: streamId,
      authType: externalPlayerResource.authType,
      resumePoint: 0
    };

    Object.assign(videoOptions, resolveAnalyticIds({ watchable: this.watchable }));
    senderDebugger.debugPlayerMessage('[handleExternalPlayerResource] PRE CREATE ASSET: ', {
      streamId: streamId,
      streamProvider: streamProvider,
      videoObj: videoOptions,
      foundPlayableStream: this.watchable.findPlayableStream()
    });
    if (/disney/ig.test(streamProvider)) {
      videoOptions.channel = streamId;
      streamId = '';
    } else if (/espn/ig.test(streamProvider)) {
      videoOptions.resumePoint = -1;
      videoOptions.channel = streamId;
    } else if (/nbc/ig.test(streamProvider)) {
      videoOptions.assetEngine = 'nbcUni';
      videoOptions.serviceZip = this.currentTokenSummary.servicePostalCode;
      videoOptions.streamId = streamId;
      /**
       * We don't have direct access to the hlsjs player.
       * PP gave this 'hlsjsConfig' object, so we can set some defensive strategy against the player crash.
       * These properties can control the bitrate changes and initial load.
       */
      videoOptions.hlsjsConfig = {
        abrBandWidthFactor: 0.95,
        abrBandWidthUpFactor: 0.3,
        startLevel: 2
      };
      streamId = /tele/ig.test(streamId) ? 'telemundo' : streamProvider;
    }
    this.currentAsset = new this.playerPlatform.Asset.create(streamId, videoOptions);
    senderDebugger.debugPlayerMessage('[handleExternalPlayerResource] CREATED ASSET: ', {
      currentAsset: this.currentAsset,
      streamId: streamId,
      streamProvider: streamProvider,
      videoObj: videoOptions
    });
  }

  setOTTAsset(contentOptionsPassed) {
    let contentOptions;
    const playableStream = this.watchable.findPlayableStream() || {};
    const contentUrl = playableStream.contentUrl;
    const streamLocatorId = playableStream.externalStreamId || playableStream.streamId;

    if (this.watchable.isXumoStream()) {
      contentOptions = Object.assign(contentOptionsPassed, this.buildExternalOttOptions(), {
        adConfig: {},
        adInfo: {}
      });
      this.currentAsset = new this.playerPlatform.Asset.create(streamLocatorId, contentOptions);
    } else if (/espn/ig.test(contentUrl)) {
      contentOptions = Object.assign(
        this.getAnalyticOptions(contentOptionsPassed),
        this.buildESPNOttOptions(),
        { adConfig: {}, adInfo: {} }
      );
      this.currentAsset = new this.playerPlatform.Asset.create(contentUrl, contentOptions);
    }
  }
  getAnalyticOptions(opts) {
    return {
      adTargetingOptOut: opts.adTargetingOptOut,
      audienceMeasurement: opts.audienceMeasurement,
      contentType: opts.contentType,
      programId: opts.programId,
      userPrivacyString: opts.userPrivacyString
    };
  }
  buildExternalOttOptions() {
    const contentOptions = {
      assetEngine: 'hlsjs',
      assetType: 'OTT',
      assetId: '',
      brand: '',
      ottAuthCallback: this.handleOttCallback.bind(this)
    };
    return contentOptions;
  }
  buildESPNOttOptions() {
    const contentOptions = {
      assetType: 'OTT',
      assetId: '',
      brand: '',
      drmKey: '',
      ottAuthCallback: this.handleOttCallback.bind(this)
    };
    return contentOptions;
  }

  setVirtualAsset(options) {
    const contentUrl = (this.watchable.findPlayableStream() || {}).contentUrl;
    const contentOptions = Object.assign(options, {
      vss: {
        locationPostalCode:
          'urn:scte:224:audience:Zip:' + this.currentPostalCode,
        deviceType: 'urn:scte:224:audience:Device:COMPUTER',
        vssType: 'TVE'
      }
    });

    this.currentAsset = new this.playerPlatform.Asset.create(contentUrl, contentOptions);
  }

  async handleOttCallback(locator) {
    if (this.watchable.isXumoStream()) {
      const ottStreamId = (this.watchable.channel|| {}).streamId;
      const externalResource = await this.watchable.getExternalPlayerResource(ottStreamId);
      return externalResource;
    }
    const ottResource = await this.watchable.getExternalOttStream(locator);
    const payload = ottResource;
    this.adData.freewheelCAID = payload.caid;
    return payload;
  }

  resumePlayback() {
    if (this.watchable && this.watchable.isLinear()) {
      this.playCurrentAsset();
    } else if (this.api.getPlayerStatus() !== 'playing') {
      this.api.play();
    }
  }

  onSetPosition = ( detail ) => {
    this.api.setPosition(this.getValidPosition(detail.msec));
  };

  controllerListeners = {
    [controllerEvents.pause]: () => {
      if (this.playbackMonitor) {
        logger.log(`User Called Pause - userInitPause ${this.playbackMonitor.userInitPause}`);

        this.playbackMonitor.userInitPause = true;
        this.playbackMonitor.stopProgressMonitor();
        this.playbackMonitor.bufferComplete();
        logger.log(`User Called Pause - userInitPause ${this.playbackMonitor.userInitPause}`);
      }
      // Ignore pause messages during EAS alert or empty watchable
      if (this.easActive || !this.watchable) {
        return;
      }

      if (this.watchable.isLinear()) {
        this.stopPlayback();
      } else {
        this.api.pause();
      }
    },
    [controllerEvents.play]: () => {
      if (this.playbackMonitor) {
        this.playbackMonitor.userInitPause = false;
      }
    },
    [controllerEvents.seek]: ({ detail }) => {
      // Ignore seek messages during EAS alert
      if (this.easActive) {
        return;
      }
      seekMonitor.userRequestedPosition = detail.msec;
      this.onSetPosition(detail);
    },
    [controllerEvents.unload]: ({ detail }) => {
      this.stopPlayback();
      seekMonitor.reset();
      if (this.api.getPlayerStatus() !== 'error' && this.watchable && this.sendVPEventForViewSession) {
        siftTracker.tagEvent('video-played', this.getSiftVPEventData());
        this.sendVPEventForViewSession = false;
      }
      detail.controller.player = null;
      detail.controller.removeEventListenerCollection(this.controllerListeners);
    },
    [controllerEvents.setAudioTrack]: ({ detail }) => {
      if (!detail.track || (detail.track.language === this.api.getCurrentAudioLanguage())) {
        return;
      }
      (this.watchable.isLinear() || this.watchable.isTve()) ? this.api.setPreferredAudioLanguage(detail.track.language) :
        this.api.setPreferredAudioLanguageAndReloadSourceBuffers(detail.track.language);
    },
    [controllerEvents.setTextTracks]: ({ detail }) => {
      this.ccEnabled = detail.tracks.length > 0;
      this.api.setClosedCaptionsEnabled(this.ccEnabled);
    }
  };

  /** Listeners for messages from Sender application */
  senderListeners = {
    [messageTypes.controllerCreated]: ({ detail }) => {
      detail.controller.addEventListenerCollection(this.controllerListeners);
      detail.controller.player = this;
    },
    [messageTypes.shutdown]: () => {
      heartbeat.stopWatching();
      splunkLogger.logConnectionStatus('SHUTDOWN');
      splunkLogger.onVideoEnd();
      this.adMode && PlayerAdLogging.adError({ type: 'AdError', description: 'Ad abandonment by user' });
      // do not fire event if shutdown without playback
      if (this.api.getPlayerStatus() !== 'idle' && this.viewSessionStart && this.sendVPEventForViewSession) {
        siftTracker.tagEvent('video-played', this.getSiftVPEventData());
      }
    },
    [messageTypes.setTrickPlay]: ({ detail }) => {
      const speeds = this.api.getSupportedPlaybackSpeeds();
      const currentSpeed = this.api.getCurrentPlaybackSpeed();
      const currentIndex = speeds.indexOf(currentSpeed);
      const upSpeed = speeds[currentIndex + 1] ? speeds[currentIndex + 1] : currentIndex;
      const downSpeed = speeds[currentIndex - 1] ? speeds[currentIndex - 1] : currentIndex;

      if (detail.direction === 0) {
        this.api.setSpeed(1);
      } else if (detail.direction === 1) {
        this.api.setSpeed(upSpeed);
      } else if (detail.direction === -1) {
        this.api.setSpeed(downSpeed);
      }
    }
  };

  /** Listeners for messages from heartbeat */
  heartbeatListeners = {
    [heartbeatEvents.failed]: () => this.stop()
  };

  async init() {
    const playerPlatformModule = await import(
      /* webpackChunkName: "tv-player-platform" */
      '@viper/playerplatform/build/chromecast/js/PlayerPlatformAPI.js');

    this.playerPlatform = playerPlatformModule;
    this.ppEvents = playerPlatformModule.Events;
    this.videoPlayer = document.body.querySelector('#player-container');

    await this.createPlayer(this.videoPlayer);

    window.player = this;
    this.seekMonitorObj = seekMonitor;
    castMessenger.addEventListenerCollection(this.senderListeners);
    heartbeat.addEventListenerCollection(this.heartbeatListeners);

    this.addListeners(this.api, {
      [this.ppEvents.AD_BREAK_START]: (detail) => {
        if (this.watchable.isLinear()) {
          this.adData.linearAdIndex = -1;
        }
        this.adData.videoAdBreak = { videoAdBreak: detail.videoAdBreak };
        const videoAdBreak = this.adData.videoAdBreak;
        const adBreakStart = {
          type: playerEvents.adBreakStart,
          videoAdBreak: videoAdBreak
        };

        if (this.playerPosition > 6e4 && this.watchable.isLinear()) {
          this.saveResumePoint({ position: this.playerPosition - 1e4 });
        }
        this.adMode = true;
        Object.assign(this.adData, {
          adBreakStart: adBreakStart,
          adsPlaying: true,
          currentAdBreak: {
            videoAdBreak: videoAdBreak,
            duration: detail.videoAdBreak.duration,
            currentBreakPosition: 0,
            currentAdCount: 0
          },
          duration: detail.videoAdBreak.duration,
          mediaOpenedFiredDuringAdBreak: false,
          mediaProgressAdCount: 0,
          timelineAdBreaks: this.getTimeline(),
          type: playerEvents.adBreakStart
        });
        this.duration = detail.videoAdBreak.duration;
        this.playerPosition = 0;
        if (this.adData.findSkippedAdBreak(adBreakStart)) {
          console.warn('AD BREAK START  - Skipped found : ' + JSON.stringify(detail));
        }
        this.adData.mediaProgressAdCount = 0;
        castMessenger.broadcastMessage(playerEvents.adBreakStart, {
          adBreakStart: Object.assign({}, adBreakStart)
        });
        this.dispatchEvent(playerEvents.adBreakStart, adBreakStart);
        PlayerAdLogging.adBreakEvent(detail);
      },
      [this.ppEvents.AD_BREAK_COMPLETE]: (detail) => {
        this._onAdBreakComplete(detail);
        PlayerAdLogging.adBreakEvent(detail);
      },
      [this.ppEvents.AD_BREAK_EXITED]: (detail) => {
        this._onAdBreakComplete(detail);
        PlayerAdLogging.adBreakEvent(detail);
      },
      [this.ppEvents.AD_EXITED]: (detail) => {
        this._onAdComplete(detail);
      },
      [this.ppEvents.AD_ERROR]: (detail) => {
        this._onAdComplete(detail);
        PlayerAdLogging.adError(detail);
      },
      [this.ppEvents.AD_START]: (detail) => {
        if (this.watchable.isLinear()) {
          this.adData.linearAdIndex++;
        }
        const adIndex = this.watchable.isLinear() ? this.adData.currentAdIndex : detail.videoAd.index;
        this.adData.adStart = {
          adIndex: adIndex,
          adStart: detail,
          adType: this.api.adManager.type
        };

        if (detail.videoAd.skipped) {
          console.warn('AD START  - Skipped found : ' + JSON.stringify(detail));
          return;
        }
        castMessenger.broadcastMessage(playerEvents.adStart, Object.assign(this.adData.adStart,
          {
            currentAdCount: adIndex
          }));
        this.dispatchEvent(playerEvents.adStart, detail);
      },
      [this.ppEvents.AD_COMPLETE]: (detail) => {
        this._onAdComplete(detail);
      },
      [this.ppEvents.BUFFER_COMPLETE]: (detail) => {
        !this.adMode && splunkLogger.onBufferEvent(splunkTypes.BufferEnd, detail);
        this.dispatchEvent(playerEvents.bufferComplete, detail);
      },
      [this.ppEvents.BUFFER_START]: (detail) => {
        !this.adMode && splunkLogger.onBufferEvent(splunkTypes.BufferStart, detail);
        this.dispatchEvent(playerEvents.bufferStart, detail);
      },
      [this.ppEvents.AD_PROGRESS]: (detail) => {
        if (!this.adData.adsPlaying) {
          return;
        }
        this.adData.adProgress = { adProgress: detail };
        //  TODO think about handling player position for ads in adData
        this.playerPosition = Math.round(detail.position);

        if (this.watchable.isLinearTve()) {
          this.playerPosition = detail.position;
        }
        throttle('adProgressThrottle', ()=> castMessenger.broadcastMessage(messageTypes.adProgress, {
          currentAdCount: this.adData.currentAdIndex + 1,
          rate: detail.rate,
          position: this.playerPosition,
          progress: detail.progress,
          endposition: this.adData.currentAdBreak.duration, //  Math.round(detail.videoAd.endTime),
          startposition: 0,
          videoAd: detail.videoAd
        }), 1e3);
        this.dispatchEvent(playerEvents.adProgress, {
          currentAdBreak: this.adData.currentAdBreak,
          position: this.playerPosition,
          progress: detail.progress,
          endposition: this.adData.currentAdBreak.duration, // Math.round(detail.videoAd.endTime),
          startposition: 0
        });
      },

      [this.ppEvents.MEDIA_OPENED]: (detail) => {
        /*
        "{"type":"MediaOpened",
        "mediaType":"vod",
        "playbackSpeeds":[0,0.25,0.5,1,2,3,4,8],
        "availableAudioLanguages":["en"],
        "width":512,
        "height":288,
        "openingLatency":2760,
        "hasDRM":false,
        "hasCC":true}"
         */

        this._startResumePointTimer();
        this.enforceResumePointOnMediaOpened();

        const ppAssetInfo = {};
        const duration = this.getModifiedDuration();
        try {
          ppAssetInfo.duration = duration / 1e3;
        } catch (e) {
          ppAssetInfo.duration = 'NA';
        }

        try {
          ppAssetInfo.urlType = this.api.asset.getUrlType() === 'mpd' ? 'DASH' : 'HLS';
        } catch (e) {
          ppAssetInfo.urlType = 'HLS';
        }

        try {
          ppAssetInfo.assetEngineType = this.api.getAssetEngineType();
        } catch (e) {
          ppAssetInfo.assetEngineType = '';
        }

        try {
          ppAssetInfo.currentlyStreamingUrl = this.api.asset.url;
        } catch (e) {
          ppAssetInfo.currentlyStreamingUrl = '';
        }

        this.loggingInfo = ppAssetInfo;
        this.adData.timelineAdBreaks = this.getTimeline();
        senderDebugger.debugPlayerMessage('PP MEDIA_OPENED duration detail: ', {
          eventDetail: detail,
          duration: duration,
          playerStatus: this.getPlayerState()
        });
        this.configureBitrates();
        this.dispatchEvent(playerEvents.mediaOpened, Object.assign({}, {
          duration: duration,
          adData: this.adData
        }, detail));
        PlayerAdLogging.adTimelineInfo(this.adData.adConfig, this.adData.timelineAdBreaks, duration);

        this.sendVPEventForViewSession = true;
        this.viewSessionStart = Date.now();
        splunkLogger.onBufferEvent(splunkTypes.BufferEnd);
        /*
         * this is supposed to be fixed in PP
         * Adding the console warn message when this would be hit
          if (this.api.getContentPosition() === 0 || this.api.getContentPosition() <= this.forcedResumePoint) {
            this.api.setPosition(this.forcedResumePoint);
          }
        }
        */
        if (this.api.getContentPosition() === 0 || this.api.getContentPosition() <= this.forcedResumePoint) {
          console.warn(`content ${this.api.getContentPosition()} does not match resume point ${this.forcedResumePoint}`);
        }
        if (this.sendPlayerViewedEvent) {
          siftTracker.tagEvent('screen-viewed', { screen: 'Player' });
          this.sendPlayerViewedEvent = false;
        }
      },
      [this.ppEvents.MEDIAKEY_STATUS]: (ppEvent) => {
        senderDebugger.debugErrorMessage('PP MEDIAKEY_STATUS  detail: ', {
          eventDetail: ppEvent
        });
        const statuses = JSON.stringify(ppEvent.changes || '');
        const isOutputRestricted = ['output-not-allowed', 'output-restricted'].some(function(outputValue) {
          return statuses.includes(outputValue);
        });
        if (!statuses || !isOutputRestricted) {
          return;
        }
        this.errorHandler.handleMediaError(playerEvents.mediaKeyStatus, ppEvent);
      },
      [this.ppEvents.MEDIA_FAILED]: (ppEvent) => {
        senderDebugger.debugErrorMessage('PP MEDIA_FAILED duration detail: ', {
          eventDetail: ppEvent
        });
        this.errorHandler.handleMediaError(playerEvents.mediaFailed, ppEvent);
      },

      [this.ppEvents.MEDIA_RETRY]: (ppEvent) => {
        senderDebugger.debugPlayerMessage('PP MEDIA_RETRY duration detail: ', {
          eventDetail: ppEvent
        });
        splunkLogger.onBufferEvent(splunkTypes.BufferEnd);
        this.errorHandler.handleMediaError(playerEvents.mediaRetry, ppEvent);
      },

      [this.ppEvents.MEDIA_WARNING]: (ppEvent) => {
        logger.warn(ppEvent);
      },

      // TODO These detail params are really events (that have details)...
      [this.ppEvents.FRAGMENT_INFO]: (detail) => {
        this.dispatchEvent(playerEvents.fragmentInfo, detail );
      },
      [this.ppEvents.PLAYBACK_STARTED]: (detail) => {
        this._startResumePointTimer();

        senderDebugger.debugPlayerMessage('PP PLAYBACK_STARTED duration detail: ', {
          eventDetail: detail
        });
        this.dispatchEvent(playerEvents.playStateChanged, {
          ...detail,
          adsPlaying: this.adMode
        });
        splunkLogger.onVideoStart(this.watchable, this.loggingInfo);
        this.isMidStream = true;
        this.errorHandler.reset();
      },
      [this.ppEvents.MEDIA_ENDED]: (detail) => {
        this.saveResumePoint();
        if (this.sendVPEventForViewSession) {
          siftTracker.tagEvent('video-played', this.getSiftVPEventData());
          this.sendVPEventForViewSession = false;
        }
        castMessenger.broadcastMessage(messageTypes.mediaEnded);
        this.dispatchEvent(playerEvents.mediaEnded, detail );
      },
      [this.ppEvents.PLAY_STATE_CHANGED]: (detail) => {
        this.playStateChanged(detail);
        this.dispatchEvent(playerEvents.playStateChanged, {
          ...detail,
          adsPlaying: this.adMode
        });
      },
      [this.ppEvents.MEDIA_PROGRESS]: (detail) => {
        const adModePP = this.api.adManager && this.api.adManager.isAdPlaying();
        const inProgressAdCount = this.adData.mediaProgressAdCount < this.adData.mediaProgressAdMax;
        if (this.watchable.isTve() && (this.adMode || adModePP)) {
          if (inProgressAdCount) {
            console.log('in progress ad count ************ ' + this.getPlayerState()
            + '  admode: [' + this.adMode +']  adModePP:[' + adModePP +']');

            this.adData.mediaProgressAdCount++;
            if (this.adMode && adModePP) {
              return;
            }
          }

          if (this.adMode && this.adData.currentAdBreak.videoAdBreak.startTime > 0) { //  midroll ad
            this._onAdBreakComplete({ detail: {
              adsPlaying: false,
              type: playerEvents.adBreakComplete,
              videoAdBreak: this.adData.videoAdBreak
            } });
            castMessenger.broadcastMessage(messageTypes.playStateChanged, {
              type: playerEvents.playStateChanged,
              state: 'playing'
            });
          } else if (this.adMode && this.adData.currentAdBreak.videoAdBreak.startTime === 0) { //  preroll ad
            this.dispatchEvent(playerEvents.mediaOpened, {
              type: playerEvents.mediaOpenedDelayed,
              mediaOpenedDelayed: true
            });
            castMessenger.broadcastMessage(messageTypes.playStateChanged, {
              type: playerEvents.playStateChanged,
              state: 'playing'
            });
          }
          if (Math.round(this.duration) !== Math.round(this.api.getDuration())) {
            this.duration = Math.round(this.api.getDuration()) || 0;
          }
          if (this.adMode) {
            this.adMode = false;
            this.adData.adsPlaying = false;
            return;
          }
        }

        if (!this.adMode && !adModePP) {
          this.adData.mediaProgressAdCount = 0;
        }
        this.playerPosition = this.api.getCurrentPosition();
        seekMonitor.mediaProgressCheck();
        if (this.tveVodCaptionCheckEnabled && !this.api.getCurrentClosedCaptionTrack()) {
          setTimeout(() => this.api.setClosedCaptionsEnabled(this.ccEnabled), 1e4);
          this.tveVodCaptionCheckEnabled = false;
        }
        if (this.watchable.isLinear()) {
          const linearDetail = {
            position: Date.now(),
            relativePosition: Date.now() - this.watchable.startTime,
            startposition: this.watchable.startTime,
            endposition: this.watchable.endTime
          };
          this.dispatchEvent(playerEvents.mediaProgress, linearDetail);
        } else {
          this.dispatchEvent(playerEvents.mediaProgress, {
            ...detail,
            position: this.playerPosition,
            currentVideoTime: (this.watchable.isRecording() || this.watchable.isIvod()) ? this.api.player.video.currentTime : 0
          });
        }
      },
      [this.ppEvents.DURATION_CHANGED]: (detail) => {
        this._onDurationChanged(detail);
      },
      [this.ppEvents.BITRATE_CHANGED]: (changeEvent) => {
        logger.logBlock('BITRATE_CHANGED', (logger) => {
          logger.log('changeEvent:', changeEvent);
        });
        senderDebugger.debugPlayerMessage('PP BITRATE_CHANGED duration detail: ', {
          eventDetail: changeEvent
        });
        this.dispatchEvent(playerEvents.bitrateChange, changeEvent );
      },
      [this.ppEvents.SEEK_START]: (seekEvent) => {
        seekMonitor.ppSeekStartPosition = seekEvent.position;
        splunkLogger.seekStart();
      },
      [this.ppEvents.SEEK_COMPLETE]: (seekEvent) => {
        this.saveResumePoint(seekEvent);
        seekMonitor.ppSeekCompletePosition = seekEvent.position;
        seekMonitor.checkPosition();
      },
      [this.ppEvents.NUMBER_OF_ALTERNATIVE_AUDIO_STREAMS_CHANGED]: (numberOfStreams) => {
        this.dispatchEvent(playerEvents.audioStreamsChanged, { numberOfStreams });
      },
      [this.ppEvents.NUMBER_OF_ALTERNATIVE_CLOSED_CAPTIONS_STREAMS_CHANGED]: (numberOfStreams) => {
        this.dispatchEvent(playerEvents.captionsStreamsChanged, { numberOfStreams });
      },
      [this.ppEvents.EMERGENCY_ALERT_STARTED]: () => {
        logger.log('emergencyAlertStarted');
        this.easActive = true;
        this.dispatchEvent(playerEvents.emergencyAlertStarted);
        splunkLogger.onEASEvent(playerEvents.emergencyAlertStarted, this.loggingInfo, this.watchable);
      },
      [this.ppEvents.EMERGENCY_ALERT_COMPLETE]: () => {
        logger.log('emergencyAlertComplete');
        this.easActive = false;
        this.dispatchEvent(playerEvents.emergencyAlertComplete);
        splunkLogger.onEASEvent(playerEvents.emergencyAlertComplete, this.loggingInfo, this.watchable);
      },
      [this.ppEvents.EMERGENCY_ALERT_FAILURE]: () => {
        logger.log('emergencyAlertFailure');
        this.easActive = false;
        this.dispatchEvent(playerEvents.emergencyAlertFailure);
        splunkLogger.onEASEvent(playerEvents.emergencyAlertFailure, this.loggingInfo, this.watchable);
      },
      [this.ppEvents.EMERGENCY_ALERT_IDENTIFIED]: () => {
        this.clearVideoObject(this.api.player.video).then(function() {
          logger.log('emergencyAlertIdentified - cleared video object');
        });
      },
      [this.ppEvents.STREAM_BOUNDARY]: (boundaryEvent) => {
        this.dispatchEvent(playerEvents.streamBoundary, boundaryEvent);
      }
    });
    ppjsLogger.init(this);
    this.dispatchEvent(playerEvents.playerReady, this.api);
  }

  // Stop playback, but do NOT unload cast stream
  // This is meant to resumable stops to playback (like "pausing" linear)
  stopPlayback() {
    this.watchable && this.saveResumePoint();
    logger.log('Stopping playback');
    heartbeat.stop();
    heartbeat.stopWatching();


    if (this.api.getPlayerStatus() !== 'idle') {
      this.api.stop();
      if (this.easConfigured) {
        this.api.eas.stop();
      }
    }
  }

  // Fully stop. This will stop playback and tell PlayerManager to unload the cast stream
  stop() {
    logger.log('Telling PlayerManager to stop');
    // This will ultimately trigger a controllerEvents.unload event which will call stopPlayback
    castMessenger.playerManager.stop();
  }

  clearVideoObject(videoObj) {
    /**
    * This function clears the video object and media keys.
    * So it can play another video source encrypted or not.
    */
    videoObj.src = '';
    return videoObj.setMediaKeys(null);
  }

  playStateChanged({ playState }) {
    const playStateMethods = {
      'idle': this._stopResumePointTimer,
      'paused': this._stopResumePointTimer,
      'playing': () => {
        this._playerStartupComplete();
        this._startResumePointTimer();
        this.errorHandler.reset();
      },
      'prepared': this._playerStartupComplete
    };
    if (playState in playStateMethods) {
      playStateMethods[playState]();
    }
  }

  getDurationAndTimeViewed(duration) {
    const millisecsInMins = 6e4;
    const millisecsInSecs = 1e3;
    const viewedMilliseconds = Date.now() - this.viewSessionStart;
    const durationInMins = Math.round(duration / millisecsInMins);
    const durationInSecs = Math.round(duration / millisecsInSecs);
    const valueForMins = Math.round(viewedMilliseconds / millisecsInMins);
    const valueForSecs = Math.round(viewedMilliseconds / millisecsInSecs);
    const viewedPercent = Math.round(viewedMilliseconds * 100 / duration);

    if (viewedPercent <= 0) {
      return [durationInSecs, durationInMins, 0, valueForMins, valueForSecs];
    }
    if (viewedPercent === 100 || valueForMins >= durationInMins || valueForSecs >= durationInSecs) {
      return [durationInSecs, durationInMins, viewedPercent, durationInMins, durationInSecs];
    }
    return [durationInSecs, durationInMins, viewedPercent, valueForMins, valueForSecs];
  }

  getFinishedWatchingPercent(duration) {
    const position = this.playerPosition;
    const fwPercent = Math.floor((position / duration) * 100);

    if (fwPercent <= 0) {
      return 0;
    } else if (fwPercent >= 100) {
      return 100;
    }
    return fwPercent;
  }

  getSiftVPEventData() {
    if (Object.keys(this.watchable).length === 0) {
      return {};
    }

    const watchable = this.watchable;
    const creativeWork = watchable.creativeWork || {};
    const channel = watchable.channel || {};
    const duration = watchable.duration || watchable.endTime - watchable.startTime;
    const contentProvider = watchable.contentProvider || channel.contentProvider || {};
    const entityId = watchable.entityId || (creativeWork && creativeWork.entityId) || 'NA';
    const programId = watchable.programId || creativeWork.programId || 'NA';
    const providerName = contentProvider.name || 'NA';
    const providerId = watchable.providerId || 'NA';
    const isEpisode = creativeWork && creativeWork.isTvEpisode() || creativeWork && creativeWork.series;
    const isNonEpisodic = creativeWork ? (watchable.isTvSeries() && !creativeWork.episodeId || watchable.type === 'SeriesMaster') : false;
    const isSportsEvent = creativeWork && creativeWork.isSportsEvent();
    const name = isSportsEvent || isNonEpisodic ? creativeWork.title :
      (isEpisode ? (creativeWork && creativeWork.series || {}).title : watchable.derivedTitle || 'NA');
    const episodeName = (isEpisode || isNonEpisodic || isSportsEvent) && creativeWork.title ? creativeWork.title : 'NA';
    const rights =
      (watchable.isRecording() && !watchable.isTveRecording()) ||
        watchable.isVod() ||
        watchable.isPurchase() ||
        (watchable.isLinear() && !watchable.isLinearTve()) ? 'T6' : (watchable.isAnyTve() ? 'TVE' : 'NA');
    const station = watchable.isLinear() || watchable.isRecording() ?
      ((watchable.channel || {}).callSign || (watchable.channel || {}).companyCallSign) : 'NA';
    const linearCompany = (watchable.channel || {}).companyCallSign || 'NA';
    const [durationInSecs, durationInMins, percentViewed, timeViewedInMins, timeViewedInSecs] = this.getDurationAndTimeViewed(duration);
    const finishedWatchingPercent = !watchable.isLinear() ? this.getFinishedWatchingPercent(duration) : 'NA';
    const rottenTomatoesScore = String(getProperty(creativeWork, 'reviews.RT.criticSummaryScore') || 'NA' );
    const csmRating = String(getProperty(creativeWork, 'reviews.CSM.value') || 'NA');
    const closedCaptionsEnabled = this.api.getClosedCaptionsStatus();
    const sapAudioUtilized = this.getAudioTracks().some((audioTrack) => {
      return audioTrack.locale === this.api.getCurrentAudioLanguage() && !audioTrack.isDefault && audioTrack.isActive;
    });
    const isVod = watchable.isVod() || watchable.isTve();
    const streamId = watchable.streamId || watchable.getLinearProp('streamId') || 'NA';
    const recordingId = this.watchable.isRecording() ? watchable.id : 'NA';
    const assetId = watchable.assetId || watchable.auditudeId || 'NA';
    const listingId = watchable.isLinear() ? watchable.listingId : 'NA';

    let programType = '';
    let type = '';

    if (isNonEpisodic) {
      programType = 'Series';
    } else if (isEpisode) {
      programType = 'Episode';
    } else if (creativeWork && creativeWork.isMovie()) {
      programType = 'Movie';
    } else if (isSportsEvent) {
      programType = 'Sports Event';
    } else {
      programType = 'Unknown';
    }

    if (isVod && !watchable.isRental()) {
      type = 'On-Demand';
    } else if (isVod && watchable.isRental()) {
      type = 'Rental';
    } else if (watchable.isLinear()) {
      type = 'Live';
    } else if (watchable.isPurchase()) {
      type = 'Purchase';
    } else if (watchable.isRecording()) {
      type = 'DVR';
    } else {
      type = 'NA';
    }

    return {
      'name': name,
      'episode_name': episodeName,
      'is_hd': String(watchable.isHD || false),
      'rights_type': rights,
      'provider': providerName,
      'station': station,
      'linear_company': linearCompany,
      'program_type': programType,
      'type': type,
      'viewed_percentage': percentViewed,
      'viewed_mins': timeViewedInMins,
      'viewed_secs': timeViewedInSecs,
      'duration_secs': durationInSecs,
      'duration_mins': durationInMins,
      'season': String(creativeWork && creativeWork.season ? creativeWork.season : 'NA'),
      'episode': String(creativeWork && creativeWork.episode ? creativeWork.episode : 'NA'),
      'entity': entityId,
      'finished_watching_at_percentage': String(finishedWatchingPercent ? finishedWatchingPercent : '0%'),
      'common_sense_media_rating': csmRating,
      'rotten_tomatoes_critic_score': rottenTomatoesScore,
      'captions_utilized': closedCaptionsEnabled ? 'Yes' : 'No',
      'sap_audio_utilized': sapAudioUtilized ? 'Yes' : 'No',
      'user_agent': 'NA',
      'linear_program_id': watchable.isLinear() ? entityId : 'NA',
      'vod_media_guid': watchable && watchable.mediaGuid || 'NA',
      'provider_id': providerId,
      'airdate': String(creativeWork && creativeWork.datePublished || 'NA'),
      'stream_id': streamId,
      'program_id': programId,
      'recording_id': recordingId,
      'listing_id': listingId,
      'asset_id': assetId
    };
  }

  fireSiftVPOnLinearBoundary() {
    siftTracker.tagEvent('video-played', this.getSiftVPEventData());
    this.viewSessionStart = Date.now();
  }

  /**
   * createPlayer - creates an instance of PlayerPlaformAPI
   * @param  {string}  playerDiv selector for a div that PP will create the video player in.
   * @return {Promise}
   */
  async createPlayer(playerDiv) {
    await config.load(); // Assuming we'll need partner configs to setup PP
    this.ppConfig = {
      videoElement: playerDiv,
      configuration: {
        autoplay: true,
        cDvr: {
          retryOnMediaFailed: config.retryOnMediaFailed
        },
        disneyHtml5SdkVersion: '1.2.7.37',
        licenseServerUrl: config.licenseServerUrl,
        defaultAsset: {
          forceHttps: true,
          hnaEnabled: false,
          initialPolicy: 1,
          initialBitrate: 2e6,
          initialBufferTime: config.initialBuffer,
          maximumBitrate: getMaxBitrateForDevice(this.deviceCapabilities, config.maximumBitrate),
          maximumRetries: 3,
          placementStatusNotificationEndPoint: config.placementStatusNotificationEndPoint,
          playingPolicy: config.bitRatePolicy,
          playingVODBufferTime: 30e3,
          playingLinearBufferTime: 6e3,
          intersegmentDelay: 50
        },
        easAsset: {
          forceHttps: true
        },
        easUpdateInterval: 15000,
        enableMultiSiteVODDAI: config.enableMultiSiteVODDAI,
        failOnNetworkDown: true,
        fwFilterEnable: false,
        fwFilterDebug: true,
        fwFilterWatchdogDebug: false,
        fwFilterWatchdogTimeoutSeconds: 10,
        fwFilterMonitorOnly: false,
        fwFilterEnableBlacklist: true,
        fwFilterEnableTimeout: true,
        fwFilterEnableMetadataValidation: true,
        fwFilterEnableMaxBitrateToCurrentBitrate: true,
        fwFilterForceRenditionToLowestBitrate: false,
        fwFilterPrerollMaxBitrate: 1500000,
        fwFilterMinBitrateThreshold: 1000000,
        fwFilterBlackListRegex: {
          'vpaid': { url: '^https://.+$', onlyBlockVPAID: true },
          'innovid': { url: '^innovid.+$', onlyBlockVPAID: false },
          'mixed-content': { url: '^http://.+$', onlyBlockVPAID: false }
        },
        initialLogLevel: this.playerPlatform.LogLevel.ALL,

        hasSingleVideoObject: true,
        fwSingleVideoObjectDebug: true,
        fwTveVodAllowPreroll: true,

        helioEas: true,
        helioFusionEas: false,
        nbcConditionedStreamApi: {
          adobeMvpdId: 'Comcast_SSO',
          serviceUrl: 'https://medialive.digitalsvc.apps.nbcuni.com/medialive/access/live',
          channels: {
            nbc: {
              apiKey: config.nbcApiKey
            },
            telemundo: {
              apiKey: config.telemundoApiKey
            }
          }
        },
        partnerId: config.partner,
        retryOnMediaFailed: true,
        placementRequestEndpoint: 'https://acr01.ccp.xcal.tv/PlacementRequest',

        title6Linear: {
          cdnRedundant: true,
          forceHttps: true,
          playbackStalledEnabled: true,
          freeWheelConfig: getFreewheelConfig({ type: 'linearT6' }),
          enableSendingFWIFA: config.partner === 'comcast',
          enableIsLatSettings: config.partner === 'comcast'
        },

        title6Vod: {
          playbackStalledEnabled: true,
          enableSendingFWIFA: config.partner === 'comcast',
          freeWheelConfig: getFreewheelConfig({ type: 'vodT6' })
        },

        tveLinear: {
          audienceManagerEnabled: false,
          cdnRedundant: true,
          comScoreEnabled: false,
          freeWheelConfig: getFreewheelConfig({ type: 'linearTve' }),
          playbackStalledEnabled: true,
          rgbUrlRewriteHost: 'ccr.linear-tve-pil.top.comcast.net'
        },
        tveVod: {
          audienceManagerEnabled: false,
          comScoreEnabled: false,
          easEnabled: true,
          forceHttps: true,
          freeWheelConfig: getFreewheelConfig({ type: 'vodTve' }),
          playbackStalledEnabled: true
        },
        stalledTimeout: 10000,

        analyticsEndPoint: `https://analytics.xcal.tv/${config.partner}/v3/player`,
        alertServiceEndPoint: config.alertServiceEndPoint,
        maxBatchSize: 11000,
        maxQueueSize: 10,
        batchInterval: 5,
        // TODO - attach to CF Flag for sundog eas support
        // alertServiceEndPoint: `${this.wssService}${this.partition}/eas/api/alert/active/fipscode/${wsslogger.getUUID()}/`,
        updateInterval: 250,
        zipToFipsEndPoint: config.zipToFipsEndPoint
      }
    };
    this.api = new this.playerPlatform.PlayerPlatformAPI(this.ppConfig);

    splunkLogger.onPlayerReady({ playerVersion: this.api.getVersion() });
    this.errorHandler.onPlayerReady(this);
  }

  configureBitrates() {
    const bitrateProfiles = this.api.getAvailableBitrates() || [];
    this.maximumBitrate = getMaxBitrateForDevice(this.deviceCapabilities, config.maximumBitrate);
    this.api.setBitrateRange(bitrateProfiles[0], this.maximumBitrate);
  }

  configureViperAnalytics() {
    if ( !this.currentTokenSummary ) {
      logger.error('currentTokenSummary token is not set!! Viper analytics not configured!');
      return;
    }
    const hostInfo = getHostInfo({
      appName: 'X2-Chromecast',
      config: config,
      tokenSummary: this.currentTokenSummary,
      playbackQuality: false,
      inHomeCallback: () => {
        return this.currentTokenSummary.inHomeStatus === 'in-home' ? 'inHome' : 'outOfHome';
      }
    });
    logger.debug('creating player with host info:', hostInfo);
    this.api.configureAnalytics(hostInfo);
  }

  _onAdComplete( detail ) {
    const adComplete = {
      adType: detail.adType,
      position: detail.position,
      progress: detail.progress,
      rate: detail.rate,
      type: playerEvents.adComplete,
      videoAd: detail.videoAd
    };

    if (detail.type === playerEvents.adError) {
      console.warn('Ad Error found : ' + JSON.stringify(detail));
      this.adData.onAdError(detail);
      castMessenger.broadcastMessage(playerEvents.adError, detail);
    } else {
      this.adData.adComplete = { adComplete: adComplete };
    }
    if (detail.videoAd && detail.videoAd.skipped) {
      console.warn('AD COMPLETE  - Skipped found : ' + JSON.stringify(detail));
      return;
    }
    castMessenger.broadcastMessage(playerEvents.adComplete, adComplete);
    this.dispatchEvent(playerEvents.adComplete, adComplete);
  }
  /**
   * _onAdBreakComplete - fired for complete, exit, error ad break events
   * // TODO: investigate logging for exit and error types
   *
   * @param  {type} detail description
   */
  _onAdBreakComplete( detail ) {
    this.adMode = false;
    this.adData.adsPlaying = false;
    this.duration = Math.round(this.api.getDuration()) || 0;
    this.adData.type = detail.type || playerEvents.adBreakComplete;
    if (playerEvents[detail.type] === playerEvents.adError) {
      this.adData.adError = detail;
      // TODO log when errors - user story needed
    }

    logger.logBlock('_onAdBreakComplete ', (logger) => {
      logger.log('_onAdBreakComplete:', JSON.stringify(detail));
      logger.log('break complete details :', JSON.stringify(Object.assign({}, {
        videoAdBreak: detail.videoAdBreak,
        duration: this.duration,
        adMode: this.adMode
      }), null, 0));
    });
    if (this.watchable && this.watchable.isTve()) {
      seekMonitor.adBreakComplete(detail);
    } else {
      seekMonitor.checkPosition();
    }
    castMessenger.broadcastMessage(playerEvents.adBreakComplete, {
      adsPlaying: this.adMode,
      type: playerEvents[detail.type] || playerEvents.adBreakComplete
    });
    this.dispatchEvent(playerEvents.adBreakComplete, {
      videoAdBreak: detail.videoAdBreak || detail.videoAd || {},
      adsPlaying: this.adMode,
      type: playerEvents[detail.type] || playerEvents.adBreakComplete,
      state: this.api.getPlayerStatus() || '',
      watchable: this.watchable
    });
    this.tveVodCaptionCheckEnabled = this.watchable.isTve() && this.ccEnabled;
    this.dispatchEvent(playerEvents.durationChanged, this.duration);
  }
  _onDurationChanged(detail) {
    if (this.adMode) {
      return;
    }
    const duration = detail ? detail.duration : this.getModifiedDuration();
    if (this.watchable.isLinear()) {
      const linearDuration = this.watchable.endTime - this.watchable.startTime;

      if (linearDuration === this.duration) {
        return;
      }

      this.duration = linearDuration;
      this.dispatchEvent(playerEvents.durationChanged, { duration: linearDuration });
    } else {
      if (duration === this.duration) {
        return;
      }

      this.duration = duration;
      this.dispatchEvent(playerEvents.durationChanged, { duration: duration });
    }
  }

  /**
   * setAdConfig - will set up contentOptions config per the asset
   *  Starts up progress monitoring for manifestManipulator DAI
   *
   * @param  {type} contentOptions object to be passed to playerplatform for playback behaviors
   */
  setAdConfig( contentOptions ) {
    if (this.watchable.isVod() && !this.watchable.isRental() && !this.watchable.isIvod() && !config.freewheelT6VodEnabled) {
      buildManifestDAI({ contentOptions: contentOptions, deviceId: this.currentTokenSummary.deviceId });
      this.adData.adType = adTypes.manifestManipulator;
    } else if (this.watchable.isAnyVod()) {
      buildFWVodAds({ contentOptions,
        watchable: this.watchable,
        currentTokenSummary: this.currentTokenSummary });
      this.adData.adType = adTypes.freewheel;
    } else if (this.watchable.isLinearTve()) {
      buildFWTVELinearAds({ contentOptions,
        watchable: this.watchable,
        currentTokenSummary: this.currentTokenSummary,
        assetEngine: assetEngineForWatchable(this.watchable) });
      this.adData.adType = adTypes.freewheel;
    } else if (this.watchable.isLinearT6()) {
      buildFWT6LinearAds({
        contentOptions,
        watchable: this.watchable,
        currentTokenSummary: this.currentTokenSummary
      });
    }

    this.adData.adConfig = contentOptions.adConfig;
    this.adData.watchable = this.watchable;
    if (this.currentAsset && this.currentAsset.contentOptions) {
      this.currentAsset.contentOptions = {
        ...this.currentAsset.contentOptions,
        ...contentOptions
      };
    }
  }

  setSession({ tokenSummary }) {
    this.currentTokenSummary = tokenSummary;
  }

  setDeviceCapabilities(deviceCapabilities) {
    this.deviceCapabilities = deviceCapabilities;
  }

  setCurrentPostalCode(postalCode) {
    this.currentPostalCode = postalCode;
  }

  /**
   * Save current resume point
   * requires
   */
  /**
   * [saveResumePoint description]
   * @param  {string} mediaId - Default: this.watchable.mediaId
   * @param  {string} programId - Default: this.entity.programId
   * @param  {number} progress - Default: this.playerPosition
   */
  saveResumePoint({ mediaId, programId, position = 0 } = {}) {
    const progress = position || this.getContentPosition() || this.playerPosition;
    if (!programId && !this.programId) {
      return;
    }
    senderDebugger.debugResumePointMessage('save resume point detail: ', {
      progress: progress,
      playerPosition: this.playerPosition,
      position: position,
      isLinear: this.watchable && this.watchable.isLinear && this.watchable.isLinear()
    });
    if (this.watchable && this.watchable.isLinear && this.watchable.isLinear()
      || (position < 1e4 && this.playerPosition < 1e4)
      || (progress >= this.duration - 2000)
      || this.adMode) {
      return;
    }

    ResumePoints.updateResumePoint({
      deviceId: this.currentTokenSummary.deviceId,
      watchable: this.watchable,
      mediaId: mediaId || this.watchable.mediaId,
      programId: programId || this.programId,
      progress
    });
  }
  /**
   * Start making save resume point requests
   *
   * Sets an interval timer to save resume points to XTV API
   */
  _startResumePointTimer = () => {
    this._stopResumePointTimer();
    if (!resumePointInterval) {
      resumePointInterval = setInterval(() => this.saveResumePoint(), resumePointTimeInterval);
    }
  };

  _stopResumePointTimer = () => {
    clearInterval(resumePointInterval);
    resumePointInterval = null;
  };

  _playerStartupComplete = () => {
    this.videoPlayer.classList.remove('starting');
  };

  enforceResumePointOnMediaOpened = () => {
    console.log(`enforceResumePointOnMediaOpened has restartable? ${this.watchable.isRestartable} `);
    if (this.easActive || this.watchable.isListing() || (this.api.getAssetEngineType() || []).includes('helio')) {
      // At this moment we don't need it for in-house players
      return;
    }

    const resumePoint = this.checkPositionNearEnd(this.restartVideo ? 500 : this.originalResumePoint);
    if (resumePoint && (parseInt(resumePoint, 10) !== Math.floor(this.api.getContentPosition()))) {
      this.api.setPosition(resumePoint);
    }
  }

  checkPositionNearEnd = (position)=> {
    const apiAssetDuration = this.api.getDuration() || this.api.currentDuration || 0;
    const safeFromEnd = 5000;
    const isTooCloseToEnd = (apiAssetDuration - position) <= safeFromEnd;

    return isTooCloseToEnd ? apiAssetDuration - safeFromEnd : position;
  }

  getPlayerDuration() {
    return this.duration;
  }

  getAudioTracks() {
    const current = this.api.getCurrentAudioLanguage();
    const languages = this.api.getAvailableAudioLanguages();

    if (!languages || !languages.length) {
      return [];
    }
    languages.length > 2 && languages.sort((a, b) => {
      if (a === 'es' && b === 'en') return 0;
      if (a === 'en' || a === 'es') return -1;
    });

    return languages.map((locale, i) => ({
      locale,
      isDefault: (i === 0),
      isActive: (locale === current)
    }));
  }

  getCaptionTracks() {
    const enabled = this.api.getClosedCaptionsEnabled();
    const tracks = this.api.getAvailableClosedCaptionTracks();
    // Some engines don't give us the tracks array, but tell us through the hasCC() Boolean
    tracks.length === 0 && this.api.hasCC() && tracks.push('eng');
    const localeMap = {
      eng: 'en',
      spa: 'es',
      itl: 'it',
      ita: 'it',
      hin: 'hi',
      fra: 'fr',
      hun: 'hu',
      rus: 'ru',
      ger: 'de',
      deu: 'de',
      por: 'pt'
    };

    return tracks.map((locale, i) => ({
      locale: localeMap[locale] || locale,
      isActive: enabled
    }));
  }

  getContentPosition() {
    return this.api.getContentPosition();
  }

  getCurrentPosition() {
    return this.api.getCurrentPosition() || this.playerPosition;
  }

  getTimeline() {
    return this.api.getTimeline().map((videoAdBreak, index)=>{
      const adBreak = getVideoAdBreakObject({ videoAdBreak: videoAdBreak, index });
      adBreak.ads.forEach((ad, index) => {
        ad.index = index;
      });
      return adBreak;
    }) || [];
  }

  getModifiedDuration = () => {
    const durationFromAPI = this.api.getDuration();
    return (isFinite(durationFromAPI) && !isNaN(durationFromAPI) && durationFromAPI > 0) ? durationFromAPI
      : Number(this.watchable.duration);
  }

  getPlayerState() {
    return this.api ? this.api.getPlayerStatus() : 'unknown';
  }

  /**
   * getValidPosition - return a valid position depending on the player mode and input position
   * @param  {[Number]} position desired seek position
   * @return {[Number]} valid seek position within bounds
   */
  getValidPosition(position) {
    if (this.adMode && this.adData) {
      return getProperty(this.adData, 'adBreakStart.videoAdBreak.startTime') + position;
    }

    return this.checkPositionNearEnd(position);
  }

  isFFRestricted() {
    if (this.watchable.isLinear()) {
      return false;
    }
    const hasTimelineAds = (this.adData.timelineAdBreaks || []).length;
    const isFFRestrictedWatchable = this.watchable.isFFRestricted();
    return !hasTimelineAds && isFFRestrictedWatchable;
  }

  startEasAlertByAsset() {
    const easSupportedAssetTypes = ['T6 LINEAR', 'T6 VOD', 'RECORDING', 'PURCHASE', 'RENTAL', 'IVOD'];
    const currentAsssetSupportsEas = easSupportedAssetTypes.includes(this.watchable.getTypeLabel()) && !this.watchable.isTveRecording();

    if (currentAsssetSupportsEas) {
      if (!this.easConfigured) {
        this.api.configureEmergencyAlerts(this.currentTokenSummary.servicePostalCode);
        this.easConfigured = true;
      } else {
        this.api.eas.start();
      }
    } else if (this.easConfigured) {
      this.api.eas.stop();
    }
  }
}

export default new Player();
export { Player }; // For unit testing

